function expandedMenu() {
  const toggleNav = function (el) {
    const overlay = document.querySelector(".overlay");
    const contentEl = document.querySelector("body");

    if (el.classList.contains("open")) {
      el.classList.remove("open");
      overlay.style.display = "none";
      contentEl.style.overflow = "initial";
      contentEl.style.height = "auto";
    } else {
      el.classList.add("open");
      overlay.style.display = "block";
      contentEl.style.overflow = "hidden";
      contentEl.style.height = "0";
    }

    overlay.addEventListener("click", (e) => {
      toggleNav(el);
    });
  };

  function rearrangeFooterForMobile() {
    if (window.innerWidth <= 960) {
      var footerFollow = document.querySelector(".footer_follow");
      var footerRight = document.querySelector(".footer__right");

      if (footerFollow && footerRight) {
        footerFollow.classList.add("footer-col-list");
        footerRight.parentNode.insertBefore(
          footerFollow,
          footerRight.nextSibling
        );
      }
    }
  }

  $(document).ready(() => {
    const hamburgerMenu = document.querySelector(".hamburger-menu");
    const searchEl = document.querySelector(".site-branding .search");
    const closeEl = document.querySelector(".expanded-menu .close");
    const searchMenu = document.getElementById("searchmenu");
    const logoEl = document.querySelector(".site-branding .site-logo");

    const navHamburgerMenu = document.querySelector(".main-navigation .hamburger-menu");
    const navSearchEl = document.querySelector(".main-navigation .search");

    if (hamburgerMenu) {
      hamburgerMenu.addEventListener("click", (e) => {
        e.preventDefault();
        const expandedMenu = document.querySelector(".expanded-menu");
        if (expandedMenu) {
          toggleNav(expandedMenu);
        }
      });
    }
    if (searchEl) {
      searchEl.addEventListener("click", (e) => {
        e.preventDefault();
        // hamburgerMenu.classList.add("item-hide");
        // logoEl.classList.add("item-hide");
        // searchEl.classList.add("item-hide");
        // searchMenu.classList.remove("item-hide");
        // searchMenu.classList.add("item-show");
        const expandedMenu = document.querySelector(".expanded-menu");
        if (expandedMenu) {
          toggleNav(expandedMenu);
          setTimeout(() => {
            if (expandedMenu.classList.contains("open")) {
              $(".expanded-menu .search").trigger("focus");
            }
          });
        }
      });
    }

    if (navHamburgerMenu) {
      navHamburgerMenu.addEventListener("click", (e) => {
        e.preventDefault();
        const expandedMenu = document.querySelector(".expanded-menu");
        if (expandedMenu) {
          toggleNav(expandedMenu);
        }
      });
    }
    if (navSearchEl) {
      navSearchEl.addEventListener("click", (e) => {
        e.preventDefault();
        const expandedMenu = document.querySelector(".expanded-menu");
        if (expandedMenu) {
          toggleNav(expandedMenu);
          setTimeout(() => {
            if (expandedMenu.classList.contains("open")) {
              $(".expanded-menu .search").trigger("focus");
            }
          });
        }
      });
    }
    if (closeEl) {
      closeEl.addEventListener("click", (e) => {
        e.preventDefault();
        const expandedMenu = document.querySelector(".expanded-menu");
        if (expandedMenu) {
          toggleNav(expandedMenu);
        }
      });
    }

    //sticky header
    const header = document.getElementById("masthead");
    const wpAdminMenu = document.getElementById("wpadminbar");
    const branding = document.getElementById("logohead");
    const extraspace = wpAdminMenu ? wpAdminMenu.offsetHeight : 0;
    const sticky = branding ? branding.offsetHeight+ extraspace : extraspace;

    window.addEventListener(
      "scroll",
      essThrottle(function () {
        if (window.pageYOffset > sticky /*&& unstick > window.pageYOffset*/) {
          if (header) {
            header.classList.add("fixed");
          }
          if (wpAdminMenu) {
            wpAdminMenu.classList.add("fixed");
          }
        } else {
          if (header) {
            header.classList.remove("fixed");
          }
          if (wpAdminMenu) {
            wpAdminMenu.classList.remove("fixed");
          }
        }
      })
    );

    // Expanded menu sub menu toggle.
    $(".expanded-menu .current-menu-parent")
      .addClass("expanded")
      .find(".sub-menu")
      .show();
    $(".expanded-menu .menu-item-has-children > a").on("click", function (e) {
      if (e.offsetX > e.target.offsetWidth - 40) {
        e.preventDefault();
        const parent = $(e.target).parent();

        if (
          $("#menu-expanded-menu li").hasClass("expanded") &&
          !parent.hasClass("expanded")
        ) {
          $("#menu-expanded-menu li").find(".sub-menu").slideUp();
          $("#menu-expanded-menu li").removeClass("expanded");
        }
        parent.toggleClass("expanded");
        if (parent.hasClass("expanded")) {
          parent.find(".sub-menu").slideDown();
        } else {
          parent.find(".sub-menu").slideUp();
        }
      }
    });

    window.addEventListener("resize", rearrangeFooterForMobile);
    window.addEventListener("load", rearrangeFooterForMobile);
  });
}

export default expandedMenu;
